import React from "react"
import Helmet from "react-helmet"
import { ThemeProvider } from "styled-components"

import Seo from "../SEO"

import config from "../../../site-config"

import GlobalStyle from "../theme/GlobalStyle"
import ThemeDefault from "../theme/ThemeDefault"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
//import SEO from "../SEO"
import { isBrowser } from "../../components/helpers/helpers"

import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'

if (typeof window !== "undefined") {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}


const Base = ({ children, context }) => {


  const crumbs = (context !== undefined) ? context.breadcrumb.crumbs : null;
  const breadcrumb = crumbs ? { crumbs } : null

  //const customCrumbLabel = isBrowser() ? window.location.pathname.toLowerCase().replace('-', ' ') : ''

  const pagesToHideBreadcrumbs = [
    "/",
    "/pricing/",
    "/login/",
    "/sign-up/",
    "/404/",
  ]

  return (
    <ThemeProvider theme={ThemeDefault}>
      <React.Fragment>
        <GlobalStyle />
        <Helmet title={config.siteTitle}>
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/2333428.js"></script>
          <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
        </Helmet>        
        <Seo data={context} />
        <div className="wrapper">
          <main>
            { context && !pagesToHideBreadcrumbs.includes(context.uri) && <section className="breadcrumbs-section">
              <div className="inner-div" style={{padding: "16px 24px 8px 24px"}}>
                <Breadcrumb
                    crumbs={crumbs}
                    crumbSeparator=" » "
                    crumbLabel={context.title}
                /> 
              </div>
            </section> }    
            {children}
          </main>
        </div>
      </React.Fragment>
    </ThemeProvider>
  )
}

Base.defaultProps = {
  cta: false,
}

export default Base
